import {
    Button,
    Column,
    EColumnAlignment,
    EComponentColor,
    EComponentSize,
    ERowAlignment,
    ERowJustification,
    Hint,
    Label,
    Row,
    TextArea,
    TextField,
} from '@hyperclap/ui';
import React, { useState } from 'react';

import { castToArray } from '@common';
import { TApp } from '@hooks';
import { IGame, ITag, IUser } from '@typings';
import { GameSelect, StreamerSelect, TagSelect } from '@views';
import { Video } from '@views/fragments/Sticker/components';

import s from './AddDetails.scss';

interface IAddDetailsProps extends TApp {}

export const AddDetails = (props: IAddDetailsProps) => {
    const {
        stickersCreation: {
            preparedData,
            sticker,
            back,
            createSticker,
        },
        translation: {
            t,
        },
    } = props;

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [streamer, setStreamer] = useState<IUser>();
    const [game, setGame] = useState<IGame>();
    const [tags, setTags] = useState<ITag[]>();

    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    const createStickerClick = () => {
        void createSticker(name, description, streamer, tags, game);
    };

    return (
        <Column grow rowGap={10} alignment={EColumnAlignment.CENTER}>
            <Row margin={{ bottom: 20 }} padding={{ top: 40 }}>
                <Column width={350}/>
                <Column
                    rowGap={10}
                    alignment={EColumnAlignment.STRETCH}
                    width={470}
                >
                    <Video
                        source={sticker ? sticker.stickerUrl : preparedData?.preparedUrl}
                        maxWidth={500}
                        maxHeight={350}
                        onReadyStateChange={(ready) => setIsVideoLoaded(ready)}
                    />
                </Column>
                <Column width={350}/>
            </Row>
            <Row margin={{ bottom: 60 }} alignment={ERowAlignment.TOP}>
                <Column width={350}/>
                <Column
                    rowGap={10}
                    alignment={EColumnAlignment.STRETCH}
                    width={470}
                >
                    { isVideoLoaded &&
                        <>
                            <TextField
                                margin={{ top: 10 }}
                                maxLength={50}
                                placeholder={t('sticker-creation.add-details.sticker-name')}
                                onChanged={(val) => setName(val)}
                            />

                            <TextArea
                                maxLength={300}
                                placeholder={t('sticker-creation.add-details.sticker-description')}
                                onChange={(val) => setDescription(val?.toString() || '')}
                            />

                            <StreamerSelect
                                placeholder={t('sticker-creation.add-details.streamer-nickname')}
                                debounceTime={500}
                                maxLength={100}
                                onSelect={setStreamer}
                            />

                            <GameSelect
                                placeholder={t('sticker-creation.add-details.game-name')}
                                debounceTime={500}
                                maxLength={100}
                                onSelect={setGame}
                            />

                            <Row height={80} alignment={ERowAlignment.TOP}>
                                <TagSelect
                                    fullWidth
                                    placeholder={t('sticker-creation.add-details.add-tag')}
                                    debounceTime={500}
                                    maxLength={20}
                                    onTagsChanged={setTags}
                                />
                            </Row>

                            <Row columnGap={15} padding={{ top: 15 }} justification={ERowJustification.END}>
                                <Button
                                    caption={t('common.back')}
                                    linkStyle
                                    width={120}
                                    onClick={back}
                                />

                                <Button
                                    caption={t('common.next')}
                                    color={EComponentColor.ACCENT}
                                    disabled={!name.length}
                                    width={160}
                                    onClick={createStickerClick}
                                />
                            </Row>
                        </>
                    }
                </Column>
                <Column width={350}>
                    { isVideoLoaded &&
                        <Hint muted padding={{ left: 40 }} textSize={EComponentSize.SMALL} className={s.description}>
                            <Label
                                caption={t('sticker-creation.add-details.description.title')}
                                size={EComponentSize.SMALL}
                                muted
                                useBoldFont
                            />
                            <ul>
                                {
                                    castToArray<string>(t('sticker-creation.add-details.description.lines', { returnObjects: true }))
                                        .map((element, idx) => <li key={idx}>{element}</li>)
                                }
                            </ul>
                        </Hint>
                    }
                </Column>
            </Row>
        </Column>
    );
};
