import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconExclamationOctagonLine } from '@assets/images/svg';

import { ControlItem } from '../ControlItem';

interface IControlReportProps {
    onClick(): void;
}

export const ControlReport = (props: IControlReportProps) => {
    const { onClick } = props;
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`sticker-popup.bottom.controls.${id}`);

    return (
        <ControlItem
            title={t('report')}
            icon={<IconExclamationOctagonLine/>}
            onClick={onClick}
        />
    );
};
