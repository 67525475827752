import { Column } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_SOUND_VOLUME } from '@common';
import { ISticker, SizeCalculationBase } from '@typings';
import { Sticker } from '@views';

import { IconAngleLeftLine, IconAngleRightLine } from './images';
import s from './StickersBand.scss';

interface StickersBandProps {
    caption: string;
    description? : string;
    defaultSoundVolume?: number;
    displayShowAll?: boolean;
    icon?: JSX.Element;
    iconClassName?: string;
    isMobileLayoutRecommended?: boolean;
    isAppleMobileDevice?: boolean,
    stickers: Array<ISticker>;
    stickersSoundEnabled?: boolean;
    onShowMoreClick?: () => void;
    onStickerClick?: (sticker: ISticker) => void;
    onSwitchSound?: () => void;
}

let timer: NodeJS.Timeout;

export const StickersBand = (props: StickersBandProps) => {
    const {
        caption,
        description,
        defaultSoundVolume = DEFAULT_SOUND_VOLUME,
        displayShowAll,
        icon,
        iconClassName,
        isAppleMobileDevice,
        isMobileLayoutRecommended,
        stickers,
        stickersSoundEnabled,
        onShowMoreClick = noop,
        onStickerClick = noop,
    } = props;

    const { t } = useTranslation();

    const styles = isMobileLayoutRecommended
        ? { maxWidth: screen.availWidth - 20 } // Minus paddings
        : { maxWidth: 1004 };

    const [isLeftButtonVisible, setIsLeftButtonVisible] = useState(false);
    const [isRightButtonVisible, setIsRightButtonVisible] = useState(true);
    const [isShowAllVisible, setIsShowAllVisible] = useState(false);

    const bandRef = useRef<HTMLDivElement>(null);

    const items = stickers.map((sticker, idx) => (
        <div className={s.stickerContainer} key={idx}>
            <Sticker
                key={idx}
                className={s.sticker}
                sticker={sticker}
                withSound={stickersSoundEnabled}
                soundOnHover
                loop
                useFadeInOnLoaded
                useSkeletonOnLoading
                useFallbackVideo={isAppleMobileDevice}
                useAnimatedPreview={isMobileLayoutRecommended}
                volume={sticker.customSettings?.customVolume || defaultSoundVolume}
                sizeCalculationBase={SizeCalculationBase.HEIGHT}
                onClick={onStickerClick}
            />
        </div>
    ));

    const onBandScroll = () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            if (bandRef.current) {
                setIsLeftButtonVisible(!!bandRef.current.scrollLeft);
                setIsRightButtonVisible(bandRef.current.scrollWidth - bandRef.current.scrollLeft > bandRef.current.clientWidth);
            }
        }, 100);
    };

    const onScrollLeft = () => {
        const scrollSize = bandRef.current?.clientWidth ?? 0;
        bandRef.current?.scrollBy({ left: -scrollSize, behavior: 'smooth' });
    };

    const onScrollRight = () => {
        if (bandRef.current && (bandRef.current.scrollWidth - bandRef.current.scrollLeft > bandRef.current.clientWidth)) {
            const scrollSize = bandRef.current.clientWidth - 200;
            bandRef.current?.scrollBy({ left: scrollSize, behavior: 'smooth' });
            setIsLeftButtonVisible(true);
        }
    };

    useEffect(() => {
        if (bandRef.current) {
            setIsRightButtonVisible(bandRef.current.scrollWidth > bandRef.current.clientWidth);
            setIsShowAllVisible(bandRef.current.scrollWidth > bandRef.current.clientWidth);
        }
    }, [bandRef.current?.scrollWidth]);

    return (
        <div
            className={s.stickersBand}
            style={styles}
        >
            <Column className={s.stickersBandColumn}>
                {!isMobileLayoutRecommended
                    ? <div className={s.caption}>
                        {!!icon &&
                            <div className={cn(s.captionTag, iconClassName)}>{icon}</div>
                        }

                        <div className={s.captionLabel} onClick={onShowMoreClick}>
                            {caption}
                        </div>

                        { !!onShowMoreClick &&
                            <div className={s.captionIcon} onClick={onShowMoreClick}>
                                <IconAngleRightLine />
                            </div>
                        }
                        <div className={s.expander}/>
                        { displayShowAll &&
                            <div className={cn(s.showAll, { [s.showAllVisible]: isShowAllVisible })} onClick={onShowMoreClick}>
                                <div>{isMobileLayoutRecommended ? t('common.all') : t('streamer-area.show-more')}</div>
                                <div className={s.showAllIcon}><IconAngleRightLine /></div>
                            </div>
                        }
                    </div>
                    : <div className={s.mobileCaption} onClick={onShowMoreClick}>
                        <div className={s.mobileCaptionLabel}>
                            {caption}
                        </div>
                        { !!onShowMoreClick &&
                            <div className={s.mobileCaptionIcon} onClick={onShowMoreClick}>
                                <IconAngleRightLine />
                            </div>
                        }
                    </div>
                }

                {!!description &&
                    <div className={s.description}>
                        {description}
                    </div>
                }

                <div className={s.stickersBandScrollable} ref={bandRef} onScroll={onBandScroll}>
                    <div className={cn(
                        s.stickersList,
                        {
                            [s.stickersListMobileLayout]: isMobileLayoutRecommended,
                        },
                    )}>
                        {items}
                    </div>
                </div>

            </Column>

            { !isMobileLayoutRecommended &&
                <div
                    className={cn(
                        s.button,
                        s.buttonLeft,
                        {
                            [s.buttonVisible]: isLeftButtonVisible,
                        },
                    )}
                    onClick={onScrollLeft}
                >
                    <div className={s.buttonIcon}>
                        <IconAngleLeftLine />
                    </div>
                </div>
            }

            { !isMobileLayoutRecommended &&
                <div
                    className={cn(
                        s.button,
                        s.buttonRight,
                        {
                            [s.buttonVisible]: isRightButtonVisible,
                        },
                    )}
                    onClick={onScrollRight}
                >
                    <div className={s.buttonIcon}>
                        <IconAngleRightLine />
                    </div>
                </div>
            }
        </div>
    );
};
