import { Button, Column, EColumnAlignment, EComponentColor, EComponentSize } from '@hyperclap/ui';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { IconExclamationOctagonLine, IconSmileSadSolid } from '@assets/images/svg';
import { useApp } from '@hooks';
import { useStickers } from '@hooks/app/stickers';
import { useAppSelector } from '@hooks/store/AppSelector';
import { ISticker } from '@typings';
import {
    Empty,
    EStickerPopupControlsMode,
    FloatingHeader,
    Footer,
    SendPopup,
    StreamerAreaPage,
    useStickerPopup,
} from '@views';


import { StreamerInfoBlock } from './components';
import s from './StreamerArea.scss';


export const StreamerArea = () => {
    const app = useApp();
    const {
        streamerChannelName,
        currentUser: {
            currentUser,
        },
        deviceInfo: {
            isMobileLayoutRecommended,
        },
        events: {
            registerStreamerPageOpenEvent,
        },
        navigation: {
            goToStreamerPage,
            goToHome,
        },
        stickers: {
            selectedSticker,
            resetStickerToSend,
        },
        streamer: {
            streamer,
            noStreamer,
        },
        streamerArea: {
            stickers: {
                catalogue: {
                    stickers,
                },
            },
        },
        translation: {
            t,
        },
    } = app;

    const {
        changeStickerCustomSettings,
        addStickerToFavorites,
        removeStickerFromFavorites,
        addStickerToChannel,
        removeStickerFromChannel,
        deleteSticker,
    } = useStickers({
        streamerChannelName: currentUser?.channel?.name,
    });

    const updateStickerFavoriteState = async (sticker: ISticker) => {
        if (sticker.isFavorite) {
            return removeStickerFromFavorites(sticker);
        } else {
            return addStickerToFavorites(sticker);
        }
    };

    const updateStickerInChannelState = async (sticker: ISticker) => {
        if (sticker.isAddedToChannel) {
            return removeStickerFromChannel(sticker);
        } else {
            return addStickerToChannel(sticker);
        }
    };

    const {
        showStickerPopup,
        setStickerPopupItem,
    } = useStickerPopup({
        ...app,
        updateCustomSettings: changeStickerCustomSettings,
        updateFavoriteState: updateStickerFavoriteState,
        updateInChannelState: updateStickerInChannelState,
        deleteSticker: deleteSticker,
        controlsMode: EStickerPopupControlsMode.SEND,
    });

    const isFloatingHeaderVisible = useAppSelector((state) => state.app.isStreamerPageFloatingHeaderVisible);

    const onLogoClick = () => goToStreamerPage(streamer?.channel.link);

    useEffect(() => {
        const isStickerPopupVisible = !!selectedSticker;

        if (isStickerPopupVisible) {
            setStickerPopupItem(selectedSticker);
            showStickerPopup();
        }
    }, [selectedSticker]);

    useEffect(() => {
        if (streamer && streamer.channel.name) {
            document.title = `${streamer.channel.name} - MemeAlerts`;
        }

        if (streamer && currentUser && streamer.channel.link) {
            registerStreamerPageOpenEvent({
                streamerId: streamer.id,
                streamerName: streamer.channel.name,
                userId: currentUser.id,
            });
        }
    }, [streamer, currentUser]);

    return (
        <StreamerAreaPage {...app}>
            <Column grow className={cn(s.contentWrapper, { [s.contentWrapperMobileLayout]: isMobileLayoutRecommended } )}>
                { streamer &&
                    <StreamerInfoBlock {...app}/>
                }
                { noStreamer &&
                    <Column rowGap={30} alignment={EColumnAlignment.CENTER}>
                        <Empty
                            icon={<IconExclamationOctagonLine/>}
                            iconSize={EComponentSize.EXTRA_LARGE}
                            text={t('streamer-area.channel-not-found', { streamerChannelName })}
                            padding={{ top: 100 }}
                        />
                        <Button
                            caption={t('streamer-area.to-home')}
                            color={EComponentColor.ACCENT}
                            icon={<IconSmileSadSolid/>}
                            width={200}
                            onClick={goToHome}
                        />
                    </Column>
                }
                <FloatingHeader
                    {...app}
                    visible={isFloatingHeaderVisible && stickers.length > 0}
                    onLogoClick={onLogoClick}
                />
                <Outlet context={app}/>

                {!isMobileLayoutRecommended &&
                    <Footer padding={{ left: 15, right: 15 }} hideThemeSwitch {...app} />
                }

                {/* <div*/}
                {/*    className={cn(*/}
                {/*        s.sendStickerShadow,*/}
                {/*        {*/}
                {/*            [s.sendStickerShadowHidden]: !isSendStickerOverlayVisible,*/}
                {/*        },*/}
                {/*    )}*/}
                {/*    onClick={resetStickerToSend}*/}
                {/* >*/}
                {/*    <div className={cn(*/}
                {/*        s.sendStickerWindowContainer,*/}
                {/*        {*/}
                {/*            [s.sendStickerWindowContainerMobileLayout]: isMobileLayoutRecommended,*/}
                {/*        },*/}
                {/*    )}>*/}
                {/*        <SendPopup*/}
                {/*            {...app}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/* </div>*/}
            </Column>
        </StreamerAreaPage>
    );
};
