import { ERowJustification, Row } from '@hyperclap/ui';
import React from 'react';

import { ISticker } from '@typings';

import s from './Bottom.scss';
import {
    Controls,
    ControlsStreamer,
    ControlsViewer,
    ControlsSend,
    Flags,
    SentCounter,
} from './components';
import { EStickerPopupControlsMode } from '../../enums';
import { IStickerPopupData } from '../../types';

interface IBottomProps extends IStickerPopupData {
    sticker: ISticker;
    updateSticker(stickerData: ISticker): void;
}

export const Bottom = (props: IBottomProps) => {
    const {
        sticker,
        currentUser: { currentUser },
        updateSticker,
        controlsMode,
    } = props;

    return (
        <div className={s.root}>
            {controlsMode === EStickerPopupControlsMode.SEND && (
                <ControlsSend/>
            )}

            {controlsMode === EStickerPopupControlsMode.VIEWER && (
                <ControlsViewer
                    sticker={sticker}
                    currentUser={currentUser}
                    updateSticker={updateSticker}
                />
            )}

            {controlsMode === EStickerPopupControlsMode.STREAMER && (
                <ControlsStreamer
                    sticker={sticker}
                    currentUser={currentUser}
                    updateSticker={updateSticker}
                />
            )}

            <Row
                className={s.bottomLine}
                justification={ERowJustification.SPACE_BETWEEN}
            >
                <div className={s.bottomLineLeft}>
                    <Flags sticker={sticker}/>
                    <SentCounter sticker={sticker}/>
                </div>
                <div className={s.bottomLineRigth}>
                    <Controls {...props}/>
                </div>
            </Row>
        </div>
    );
};
