import { Row } from '@hyperclap/ui';
import React from 'react';

import { ISticker, SizeCalculationBase } from '@typings';
import { Sticker } from '@views';

import s from './Content.scss';
import { IStickerPopupData } from '../../types';

interface IContentProps extends IStickerPopupData {
    sticker: ISticker;
}

export const Content = (props: IContentProps) => {
    const { sticker } = props;

    return (
        <Row className={s.root}>
            <div className={s.left}/>
            <div className={s.center}>
                <Sticker
                    className={s.sticker}
                    classNameVideo={s.stickerVideo}
                    sticker={sticker}
                    // volume={stickerVolume}
                    withSound
                    loop={true}
                    // videoRef={videoRef}
                    sizeCalculationBase={SizeCalculationBase.HEIGHT}
                    useRecalculateDimensions={false}
                    // onLoaded={onStickerLoaded}
                />
            </div>
            <div className={s.right}/>
        </Row>
    );
};
