import { Button, Column, EColumnAlignment, EComponentColor, Loader, Row } from '@hyperclap/ui';
import React, { useState } from 'react';

import { IconImageSolid } from '@assets/images/svg';
import { TApp } from '@hooks';
import { AudioUploader } from '@views';
import { Video } from '@views/fragments/Sticker/components';

interface IAddAudioProps extends TApp {}

export const AddAudio = (props: IAddAudioProps) => {
    const {
        stickersCreation: {
            audioFile,
            isPreviewTaskInProcess,
            overrideEmbeddedAudio,
            preparedData,
            sticker,
            back,
            changeAudio,
            next,
            playPreview,
        },
        translation: {
            t,
        },
    } = props;

    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    return (
        <Column grow margin={{ bottom: 60 }} rowGap={10} alignment={EColumnAlignment.CENTER}>
            <Column
                rowGap={10}
                margin={{ top: 40 }}
                alignment={EColumnAlignment.STRETCH}
                width={470}
            >
                <Video
                    source={sticker ? sticker.stickerUrl : preparedData?.preparedUrl}
                    maxWidth={500}
                    maxHeight={350}
                    onReadyStateChange={(ready) => setIsVideoLoaded(ready)}
                />

                {preparedData && isVideoLoaded &&
                    <Row margin={{ top: 20 }}>
                        <AudioUploader
                            file={audioFile}
                            handleChange={changeAudio}
                            overrideEmbeddedAudio={overrideEmbeddedAudio}
                            preparedResult={preparedData}
                        />
                    </Row>
                }

                {isVideoLoaded &&
                    <Row columnGap={15}>
                        <Button
                            caption={t('common.back')}
                            linkStyle
                            width={120}
                            onClick={back}
                        />

                        <Button
                            caption={t('common.preview')}
                            icon={
                                isPreviewTaskInProcess
                                    ? <Loader size={20}/>
                                    : <IconImageSolid/>
                            }
                            width={160}
                            onClick={playPreview}
                        />

                        <Button
                            caption={t('common.next')}
                            color={EComponentColor.ACCENT}
                            disabled={!(audioFile || (preparedData?.hasAudio && !overrideEmbeddedAudio))}
                            width={160}
                            onClick={next}
                        />
                    </Row>
                }
            </Column>
        </Column>
    );
};
