import {
    Button,
    Column,
    Divider,
    EComponentColor,
    EComponentSize,
    ERowJustification,
    Loader,
    Row,
    Slider,
    TextField,
    useModals,
} from '@hyperclap/ui';
import cn from 'classnames';
import React, { createRef, useEffect, useRef, useState } from 'react';

import {
    IconCheckCircleSolid,
    IconCloseLine,
    IconCopyrightLine,
    IconEtmaASignSolid,
    IconEtmaESignSolid,
    IconEtmaMSignSolid,
    IconEtmaTSignSolid,
    IconHeartSolid,
    IconSendMessageLine,
    IconShitSolid,
    IconSoundActiveLine,
    IconTrashSolid,
    IconTwitchUnsafeLine,
} from '@assets/images/svg';
import { DEFAULT_SOUND_VOLUME, DEFAULT_STICKER_PRICE } from '@common';
import { Avatar } from '@components';
import { TApp } from '@hooks';
import { EtmaRating, ISticker, IStickerCustomSettings, ModerationState, SizeCalculationBase } from '@typings';
import { Sticker } from '@views';

import s from './StickerPopup.scss';

interface StickerPopupProps extends TApp {
    sticker: ISticker;
    updateCustomSettings: (a: ISticker, s: IStickerCustomSettings) => Promise<ISticker>;
    updateFavoriteState: (a: ISticker) => Promise<ISticker>;
    updateInChannelState: (a: ISticker) => Promise<ISticker>;
    deleteSticker: (a: ISticker) => Promise<ISticker>;
}

export const StickerPopupOld = (props: StickerPopupProps) => {
    const {
        sticker,
        currentUser: {
            currentUser,
        },
        navigation: {
            goToSearch,
        },
        personalArea: {
            mine: {
                deleteLoadedSticker,
            },
        },
        translation: {
            t,
        },
        updateCustomSettings,
        updateFavoriteState,
        updateInChannelState,
        deleteSticker,
    } = props;

    const videoRef = useRef<HTMLVideoElement | null>();

    const deleteLayerRef = createRef<HTMLDivElement>();

    const { closeModal } = useModals();

    const [stickerInstance, setStickerInstance] = useState<ISticker>(sticker);
    const [stickerCustomPrice, setStickerCustomPrice] = useState<number | undefined>(sticker.customSettings?.customPrice);
    const [stickerCustomVolume, setStickerCustomVolume] = useState<number | undefined>(sticker.customSettings?.customVolume);
    const [saveDisabled, setSaveDisabled] = useState(true);

    const [isVisibleDeleteLayer, setIsVisibleDeleteLayer] = useState(false);
    const [isDeleteInProcess, setIsDeleteInProcess] = useState(false);

    const isStreamer = !!currentUser?.channel.donationAlertsUsername;
    const isInFavorites = stickerInstance.isFavorite;
    const isInChannel = stickerInstance.isAddedToChannel;
    const isDenied = stickerInstance.customSettings?.disableSticker;

    const stickerPrice = stickerInstance.customSettings?.customPrice || currentUser?.channel.catalogueStickerPrice || DEFAULT_STICKER_PRICE;
    const stickerVolume = stickerInstance.customSettings?.customVolume || currentUser?.channel.soundVolume || DEFAULT_SOUND_VOLUME;

    const onStickerPriceChanged = (value: string) => {
        if (value && !isNaN(Number(value))) {
            setStickerCustomPrice(Number(value));
        }
    };

    const onVolumeChange = (value: number) => {
        if (stickerVolume === value) return;
        if (videoRef.current) videoRef.current.volume = value / 100;
        setStickerCustomVolume(value);
    };

    const onSwitchDenied = async () => {
        const result = await updateCustomSettings(
            stickerInstance, {
                ...stickerInstance.customSettings,
                _id: undefined, // TODO: Remove it (need to do on the server)
                disableSticker: !(stickerInstance.customSettings?.disableSticker ?? false),
            });
        setStickerInstance(result);
    };

    const onSwitchFavorite = async () => {
        const result = await updateFavoriteState(stickerInstance);
        setStickerInstance({ ...result, likedCount: result.likedCount, isFavorite: result.isFavorite });
    };

    const onSwitchInChannel = async () => {
        const result = await updateInChannelState(stickerInstance);
        setStickerInstance({ ...result, isAddedToChannel: result.isAddedToChannel });
    };

    const onPlayStickerClick = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
            videoRef.current.volume = (
                stickerInstance.customSettings?.customVolume ||
                currentUser?.channel?.soundVolume ||
                DEFAULT_SOUND_VOLUME
            ) / 100;
            videoRef.current.play();
        }
    };

    const onSaveCustomSettingClick = async () => {
        const result = await updateCustomSettings(
            stickerInstance, {
                ...stickerInstance.customSettings,
                _id: undefined, // TODO: Remove it (need to do on the server)
                customPrice: stickerCustomPrice,
                customVolume: stickerCustomVolume || stickerVolume,
            });
        setStickerInstance(result);
        setSaveDisabled(true);
    };

    const onDeleteShadowLayerClick = (event: React.MouseEvent<HTMLElement>) => {
        if (event.target !== deleteLayerRef.current) return;
        event.stopPropagation();

        setIsVisibleDeleteLayer(false);
    };

    const onTagClick = (tagName: string) => {
        closeModal();
        goToSearch(tagName);
    };

    const onDeleteCancelClick = async () => {
        setIsVisibleDeleteLayer(false);
    };

    const onDeleteClick = async () => {
        try {
            setIsDeleteInProcess(true);
            const deletedSticker = await deleteSticker(stickerInstance);

            if (deletedSticker) {
                deleteLoadedSticker(deletedSticker);
                setIsDeleteInProcess(false);
            }

            closeModal();
        } catch {
            setIsDeleteInProcess(false);
        }
    };

    useEffect(() => {
        const disabled = stickerInstance.customSettings?.customPrice === stickerCustomPrice &&
            stickerInstance.customSettings?.customVolume === stickerCustomVolume;
        setSaveDisabled(disabled);
    }, [stickerCustomPrice, stickerCustomVolume]);

    const onStickerLoaded = (sticker: ISticker) => {
        if (sticker && sticker.videoData && videoRef.current) {
            if ((stickerInstance.videoData?.aspectRatio ?? 1) >= 0.67111) {/* 302 / 450 - width / height */
                videoRef.current.style.width = '100%';
                videoRef.current.style.height = 'auto';
            } else {
                videoRef.current.style.width = 'auto';
                videoRef.current.style.height = '100%';
            }
        }
    };

    return (
        <div className={s.stickerPopup}>
            {isVisibleDeleteLayer &&
                <div className={s.deleteLayer} ref={deleteLayerRef} onClick={onDeleteShadowLayerClick}>
                    <div className={s.content}>
                        <div className={s.question}>{t('sticker-popup-old.delete-modal-title')}</div>
                        <div className={s.textHint}>
                            {t('sticker-popup-old.delete-warning')}
                        </div>
                        {isDeleteInProcess
                            ? <div className={s.loader}>
                                <Loader className={s.spinner}/>
                            </div>
                            : <div className={s.buttons}>
                                <Button
                                    caption={t('common.delete')}
                                    fullWidth
                                    onClick={onDeleteClick}
                                    color={EComponentColor.DANGER}
                                />
                                <Button
                                    caption={t('common.cancel')}
                                    fullWidth
                                    onClick={onDeleteCancelClick}
                                    color={EComponentColor.WARNING}
                                />
                            </div>
                        }
                    </div>
                </div>
            }
            <div className={s.stickerContainer}>
                <div className={s.stickerContainerName}>
                    {sticker.name}
                </div>
                <div className={s.stickerContainerSticker}>
                    <Sticker
                        className={s.sticker}
                        sticker={sticker}
                        volume={stickerVolume}
                        withSound
                        loop={false}
                        videoRef={videoRef}
                        sizeCalculationBase={SizeCalculationBase.WIDTH}
                        onLoaded={onStickerLoaded}
                    />
                </div>
                {sticker && sticker.tags && sticker.tags.length > 0 &&
                    <div className={s.tag}>
                        {sticker.tags.slice(0, 5).map((tag) => (
                            <div className={s.tagItem} key={tag.name} onClick={() => onTagClick(tag.name)}>
                                <div className={s.tagItemText}>#{tag.name}</div>
                            </div>
                        ))}
                    </div>
                }
                <div className={s.expander}/>
                <div className={s.stickerContainerFooter}>
                    <div className={s.stickerContainerFooterAvatar}>
                        <Avatar rounded size={EComponentSize.SMALL}/>
                    </div>
                    <div className={s.stickerContainerFooterCreator}>
                        {sticker.creatorName}
                    </div>
                    <div className={s.expander}/>
                    <div className={s.stickerContainerFooterSent}>
                        <div className={s.stickerContainerFooterSentIcon}><IconSendMessageLine/></div>
                        <div className={s.stickerContainerFooterSentCount}>{sticker.usageCount}</div>
                    </div>
                </div>
            </div>

            <div className={s.settingsContainer}>
                {isStreamer &&
                    <>
                        <div className={s.settingsContainerStreamerBlock}>
                            <Column>
                                <TextField
                                    caption={t('sticker-popup-old.send-price')}
                                    defaultValue={stickerPrice.toString()}
                                    invalidSymbols={/[^0-9]/}
                                    debounceTime={500}
                                    onChanged={onStickerPriceChanged}
                                />
                            </Column>
                            <Row columnGap={10}>
                                <Column grow>
                                    <Slider
                                        caption={t('sticker-popup-old.volume')}
                                        className={s.soundVolumeSlider}
                                        showPosition
                                        minValue={0}
                                        maxValue={100}
                                        initialValue={stickerVolume}
                                        realtimeUpdate
                                        onCommit={onVolumeChange}
                                    />
                                </Column>
                                <Button
                                    circle
                                    margin={{ top: 20 }}
                                    icon={<IconSoundActiveLine/>}
                                    onClick={onPlayStickerClick}
                                />
                            </Row>
                            <Row>
                                <Button
                                    caption={t('common.save')}
                                    disabled={saveDisabled}
                                    color={EComponentColor.ACCENT}
                                    fullWidth
                                    onClick={onSaveCustomSettingClick}
                                />
                            </Row>
                            <Divider />
                            <Row margin={{ bottom: 24 }} justification={ERowJustification.SPACE_BETWEEN}>
                                <Button
                                    className={cn({ [s.coloredGreen]: isInChannel })}
                                    icon={<IconCheckCircleSolid/>}
                                    width={145}
                                    caption={isInChannel ? t('sticker-popup-old.added-to-channel') : t('sticker-popup-old.add-to-channel')}
                                    onClick={onSwitchInChannel}
                                />
                                <Button
                                    className={cn({ [s.coloredBrown]: isDenied })}
                                    icon={<IconShitSolid/>}
                                    width={145}
                                    caption={isDenied ? t('sticker-popup-old.blacklisted') : t('sticker-popup-old.blacklist')}
                                    onClick={onSwitchDenied}
                                />
                            </Row>
                        </div>
                    </>
                }
                <div className={s.settingsContainerViewerBlock}>
                    <Row>
                        <Button
                            className={cn({ [s.coloredRed]: isInFavorites })}
                            icon={<IconHeartSolid/>}
                            caption={stickerInstance.likedCount?.toString() || '0'}
                            fullWidth
                            onClick={onSwitchFavorite}
                        />
                    </Row>
                </div>
                {(currentUser?.id === sticker.creatorId &&
                        (
                            sticker.moderationState === ModerationState.APPROVED ||
                            sticker.moderationState === ModerationState.DECLINED
                        )) &&
                    <div className={s.settingsContainerViewerBlock}>
                        <Row margin={{ top: 24, bottom: 10 }}>
                            <Button
                                iconClassName={s.deleteButtonIcon}
                                icon={<IconTrashSolid/>}
                                caption={t('common.delete')}
                                fullWidth
                                onClick={() => setIsVisibleDeleteLayer(true)}
                            />
                        </Row>
                    </div>
                }
                <div className={s.expander}/>
                <div className={s.settingsContainerFlagsBlock}>
                    {sticker.hasCopyright && <div className={cn(s.flag, s.flagCopyright)}><IconCopyrightLine/></div>}
                    {sticker.isNotTwitchSafe && <div className={cn(s.flag)}><IconTwitchUnsafeLine/></div>}
                    {sticker.etmaRating === EtmaRating.E && <div className={cn(s.flag)}><IconEtmaESignSolid/></div>}
                    {sticker.etmaRating === EtmaRating.T && <div className={cn(s.flag)}><IconEtmaTSignSolid/></div>}
                    {sticker.etmaRating === EtmaRating.M && <div className={cn(s.flag)}><IconEtmaMSignSolid/></div>}
                    {sticker.etmaRating === EtmaRating.A && <div className={cn(s.flag)}><IconEtmaASignSolid/></div>}
                </div>
            </div>

            <div className={s.closeButton} onClick={closeModal}>
                <IconCloseLine/>
            </div>

        </div>
    );
};
