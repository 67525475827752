import { Column, EComponentSize, ERowAlignment, ERowJustification, Row } from '@hyperclap/ui';
import React from 'react';

import { IconCloseLine } from '@assets/images/svg';
import { Avatar } from '@components';
import { ISticker } from '@typings';

import { TagsList } from './components';
import s from './Top.scss';
import { IStickerPopupData } from '../../types';

interface ITopProps extends IStickerPopupData {
    sticker: ISticker;
    closeModal(): void;
}

export const Top = (props: ITopProps) => {
    const {
        sticker,
        closeModal,
    } = props;

    const handleCloseClick = () => closeModal();

    return (
        <Column className={s.root}>
            <Row
                className={s.topLine}
                justification={ERowJustification.SPACE_BETWEEN}
                alignment={ERowAlignment.CENTER}
            >
                <div className={s.topLineLeft}>
                    <div className={s.creator}>
                        <Avatar
                            className={s.creatorAvatar}
                            rounded={true}
                            size={EComponentSize.SMALL}
                            source={sticker.creatorAvatar}
                        />
                        <div className={s.creatorName}>{sticker.creatorName}</div>
                    </div>
                </div>

                <div className={s.topLineCenter}>
                    <div className={s.sticker}>{sticker.name}</div>
                </div>

                <div className={s.topLineRight}>
                    <IconCloseLine
                        className={s.close}
                        onClick={handleCloseClick}
                    />
                </div>
            </Row>

            {sticker.tags && (
                <Row justification={ERowJustification.CENTER}>
                    <TagsList tags={sticker.tags}/>
                </Row>
            )}
        </Column>
    );
};
