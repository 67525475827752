import {
    Button,
    Column,
    EComponentColor,
    ERowAlignment,
    ITextFieldMethods,
    Row,
    TextField,
} from '@hyperclap/ui';
import cn from 'classnames';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconCloseLine } from '@assets/images/svg';
import { IUser } from '@typings';

import s from './BonusPopup.scss';

const MAX_BONUS_SIZE = 1000;

interface IBonusPopupProps {
    currentUser: IUser;
    visible: boolean,
    onBonusSizeChange: (val: number) => void;
    onCloseClick: () => void;
    onSendBonusClick: () => void;
}

export const BonusPopup = forwardRef<HTMLDivElement, IBonusPopupProps>((props, ref) => {
    const {
        currentUser,
        visible,
        onBonusSizeChange,
        onCloseClick,
        onSendBonusClick,
    } = props;

    const { t } = useTranslation();

    const bonusInputMethods = useRef<ITextFieldMethods>(null);

    const [bonusBtnEnabled, setButtonBonusEnabled] = useState(false);
    const [bonusSize, setBonusSize] = useState(currentUser.channel.catalogueStickerPrice || 10);

    const onBonusValueChanged = (val: string) => {
        if (!val) {
            setBonusSize(0);

            return;
        }

        if (Number(val) > MAX_BONUS_SIZE) {
            bonusInputMethods?.current?.set(MAX_BONUS_SIZE.toString());
            setBonusSize(MAX_BONUS_SIZE);
        } else if (Number(val) <= 0) {
            bonusInputMethods?.current?.set((currentUser.channel.catalogueStickerPrice || 10).toString());
            setBonusSize(currentUser?.channel?.catalogueStickerPrice || 10);
        } else {
            setBonusSize(Number(val));
        }
    };

    useEffect(() => {
        setButtonBonusEnabled(bonusSize > 0 && bonusSize <= MAX_BONUS_SIZE);
        onBonusSizeChange(bonusSize);
    }, [bonusSize]);

    return <Column className={cn(s.bonusWindow, { [s.bonusWindowInvisible]: !visible })} rowGap={10} ref={ref}>
        <Row className={s.bonusWindowLabel}>
            {t('supporters.increase-supporter-balance')}
        </Row>
        <Row height={70} alignment={ERowAlignment.TOP}>
            <TextField
                invalidSymbols={/[^0-9]/i}
                errorMessage={t('supporters.incorrect-value')}
                hasError={!bonusSize}
                width={210}
                defaultValue={(currentUser.channel.catalogueStickerPrice || 10).toString()}
                methodsRef={bonusInputMethods}
                onChanged={onBonusValueChanged}
            />
        </Row>
        <Row>
            <Button
                disabled={!bonusBtnEnabled}
                color={EComponentColor.ACCENT}
                caption={t('common.send-reward')}
                onClick={onSendBonusClick}
            />
        </Row>
        <div className={s.bonusWindowCloseButton} onClick={onCloseClick}>
            <IconCloseLine/>
        </div>
    </Column>;
});

BonusPopup.displayName = 'BonusPopup';
