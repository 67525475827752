import { applyMargin, applyPadding, Column, IBlockComponentProps, Row } from '@hyperclap/ui';
import cn from 'classnames';
import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

import { DEFAULT_MAX_CONTENT_WIDTH } from '@common';
import { TApp } from '@hooks';
import { Logo, LogoKind } from '@hyperclap/views';

import s from './Footer.scss';

interface FooterProps extends IBlockComponentProps, TApp {
    hideThemeSwitch?: boolean;
    isLogoGrayscale?: boolean;
    isLogoOpacity?: boolean;
    logoKind?: LogoKind;
    isSupportEnabled?: boolean;
    isCopyRightEnabled?: boolean;
}

export const Footer = (props: FooterProps) => {
    const {
        className,
        padding,
        margin,
        style,
        width,
        height,
        deviceInfo: {
            isMobileLayoutRecommended,
        },
        translation: {
            t,
        },
        isLogoGrayscale = true,
        isLogoOpacity = true,
        isSupportEnabled = true,
        isCopyRightEnabled = false,
        logoKind,
    } = props;

    const footerClassNames = cn({
        [s.footer]: true,
        [s.footerMobileLayout]: isMobileLayoutRecommended,
    }, className);

    const footerStyles: CSSProperties = {
        ...style,
        height,
        width,
        maxWidth: isMobileLayoutRecommended ? 'auto' : DEFAULT_MAX_CONTENT_WIDTH,
    };

    applyMargin(footerStyles, margin);
    applyPadding(footerStyles, padding);

    return (
        <Column className={footerClassNames} style={footerStyles}>
            <Row columnGap={logoKind === LogoKind.SHORT ? 25 : 65}>
                <Column>
                    <Logo
                        grayscale={isLogoGrayscale}
                        width={logoKind === LogoKind.SHORT ? 35 : 134}
                        kind={logoKind}
                        className={cn(
                            s.logo,
                            { [s.logoOpacity]: isLogoOpacity },
                        )}
                    />
                </Column>

                <Column>
                    <Row className={s.links}>
                        <Link to={'/terms'}>{t('common.terms')}</Link>
                        <Column padding={7}>{'•'}</Column>
                        <Link to={'/privacy'}>{t('common.privacy')}</Link>
                        {isSupportEnabled && (
                            <>
                                <Column padding={7}>{'•'}</Column>
                                <Link to={'/support'}>{t('common.support')}</Link>
                            </>
                        )}
                    </Row>
                </Column>
            </Row>

            {isCopyRightEnabled && (
                <Row className={cn(s.copyright, { [s.copyrightShort]: logoKind === LogoKind.SHORT })}>
                    © HyperClap. All Rights Reserved.
                </Row>
            )}
        </Column>
    );
};
