import { IModalContentWithOptions, useModals } from '@hyperclap/ui';
import React, { useEffect, useState } from 'react';

import { ISticker } from '@typings';


import s from './useStickerPopup.scss';
import { EStickerPopupControlsMode } from '../../enums';
import { StickerPopup } from '../../StickerPopup';
import { IStickerPopupData } from '../../types';

interface IStickerPopupParams extends IStickerPopupData {
    controlsMode: EStickerPopupControlsMode;
}

export const useStickerPopup = (params: IStickerPopupParams) => {
    const {
        showModal,
        setContent,
        closeModal,
    } = useModals();
    const [sticker, setSticker] = useState<ISticker>();
    const updateSticker = (stickerData: ISticker) => setSticker(stickerData);
    const content = (
        <StickerPopup
            {...params}
            sticker={sticker}
            updateSticker={updateSticker}
            closeModal={closeModal}
        />
    );

    const showStickerPopup = () => {
        const stickerPopup: IModalContentWithOptions<ISticker> = {
            content,
            options: {
                className: s.noBgModal,
                bodyClassName: s.noPaddingModal,
            },
        };
        showModal(stickerPopup);
    };

    const setStickerPopupItem = (stickerItem: ISticker) => setSticker(stickerItem);

    useEffect(
        () => {
            if (sticker) {
                setContent(content);
            }
        },
        [sticker],
    );

    return {
        showStickerPopup,
        setStickerPopupItem,
    };
};
