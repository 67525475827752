import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconTrashAltLine } from '@assets/images/svg';

import { ControlItem } from '../ControlItem';

interface IControlDeleteProps {
    onClick(): void;
}

export const ControlDelete = (props: IControlDeleteProps) => {
    const { onClick } = props;
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`sticker-popup.bottom.controls.${id}`);

    return (
        <ControlItem
            title={t('delete')}
            icon={<IconTrashAltLine/>}
            onClick={onClick}
        />
    );
};
