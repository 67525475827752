import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconCogLine } from '@assets/images/svg';

import { ControlItem } from '../ControlItem';

interface IControlSettingsProps {
    onClick(): void;
}

export const ControlSettings = (props: IControlSettingsProps) => {
    const { onClick } = props;
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`sticker-popup.bottom.controls.${id}`);

    return (
        <ControlItem
            title={t('settings')}
            icon={<IconCogLine/>}
            onClick={onClick}
        />
    );
};
