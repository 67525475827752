import cn from 'classnames';
import React from 'react';

import {
    IconCheckCircleLine,
    IconCheckCircleSolid,
    IconHeartLine,
    IconHeartSolid,
    IconShitLine,
    IconShitSolid,
} from '@assets/images/svg';
import { ISticker, IUser } from '@typings';

import s from './ControlsStreamer.scss';
import { useControlsStreamer } from './hooks';

interface IControlsStreamerProps {
    sticker: ISticker;
    currentUser?: IUser;
    updateSticker(stickerData: ISticker): void;
}

export const ControlsStreamer = (props: IControlsStreamerProps) => {
    const {
        sticker,
        currentUser,
        updateSticker,
    } = props;
    const {
        onFavoritesClick,
        onChannelClick,
        onDisableClick,
    } = useControlsStreamer({
        sticker,
        currentUser,
        updateSticker,
    });

    return (
        <div className={s.root}>
            <div
                className={s.control}
                onClick={onFavoritesClick}
            >
                {sticker.isFavorite
                    ? (
                        <IconHeartSolid
                            className={
                                cn(
                                    s.controlIcon,
                                    s.controlIconHeart,
                                    s.controlIconHeartActive,
                                )
                            }
                        />
                    )
                    : (
                        <IconHeartLine
                            className={
                                cn(
                                    s.controlIcon,
                                    s.controlIconHeart,
                                )
                            }
                        />
                    )
                }

                {sticker?.likedCount && (
                    <span className={s.controlText}>
                        {sticker.likedCount.toString()}
                    </span>
                )}
            </div>

            <div
                className={s.control}
                onClick={onChannelClick}
            >
                {sticker.isAddedToChannel
                    ? (
                        <IconCheckCircleSolid
                            className={cn(s.controlIcon, s.controlIconCheckActive)}
                        />
                    )
                    : (
                        <IconCheckCircleLine
                            className={cn(s.controlIcon)}
                        />
                    )
                }
            </div>

            <div
                className={s.control}
                onClick={onDisableClick}
            >
                {sticker.customSettings?.disableSticker
                    ? (
                        <IconShitSolid
                            className={cn(s.controlIcon, s.controlIconShitActive)}
                        />
                    )
                    : (
                        <IconShitLine
                            className={cn(s.controlIcon)}
                        />
                    )
                }
            </div>
        </div>
    );
};
