// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StickerPopup__root--ZR0Ha {
  width: 100vw;
  height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/base/views/modals/StickerPopup/StickerPopup.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;AACJ","sourcesContent":[".root {\n    width: 100vw;\n    height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `StickerPopup__root--ZR0Ha`
};
export default ___CSS_LOADER_EXPORT___;
