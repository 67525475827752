import React, {useState} from 'react';

import {ISticker} from '@typings';

import {
    ControlDelete,
    ControlPopupDelete,
    ControlPopupReport,
    ControlPopupSettings,
    ControlSettings,
} from './components';
import s from './Controls.scss';
import {EStickerPopupControlsMode} from '../../../../enums';
import {IStickerPopupData} from '../../../../types';

interface IControlsProps extends IStickerPopupData {
    sticker: ISticker;
    controlsMode: EStickerPopupControlsMode;
    updateSticker(stickerData: ISticker): void;
}

const initialPopupsState = {
    settings: false,
    delete: false,
    report: false,
};

export const Controls = (props: IControlsProps) => {
    const {
        currentUser: { currentUser },
        sticker,
        controlsMode,
        updateSticker,
    } = props;
    const [popups, setPopups] = useState(initialPopupsState);
    const isOwner = sticker.creatorId === currentUser?.id;

    const showPopup = (options: Partial<typeof popups>) => {
        setPopups({
            ...initialPopupsState,
            ...options,
        });
    };

    const handleSettingsClick = () => showPopup({ settings: true });

    const handleSettingsClose = () => showPopup({ settings: false });

    const handleDeleteClick = () => showPopup({ delete: true });

    const handleDeleteClose = () => showPopup({ delete: false });

    // const handleReportClick = () => showPopup({ report: true });

    const handleReportClose = () => showPopup({ report: false });

    return (
        <div className={s.root}>
            {popups.settings && (
                <ControlPopupSettings
                    currentUser={currentUser}
                    sticker={sticker}
                    updateSticker={updateSticker}
                    onClose={handleSettingsClose}
                />
            )}

            {popups.delete && (
                <ControlPopupDelete
                    sticker={sticker}
                    onClose={handleDeleteClose}
                />
            )}

            {popups.report && (
                <ControlPopupReport
                    sticker={sticker}
                    onClose={handleReportClose}
                />
            )}

            {controlsMode === EStickerPopupControlsMode.STREAMER && (
                <ControlSettings onClick={handleSettingsClick}/>
            )}

            {isOwner && <ControlDelete onClick={handleDeleteClick}/>}
            {/* <ControlReport onClick={handleReportClick}/>*/}
        </div>
    );
};
