import { useStickers } from '@hooks/app/stickers';
import { ISticker, IUser } from '@typings';

interface IUseControlsViewerParams {
    sticker: ISticker;
    currentUser?: IUser;
    updateSticker(stickerData: ISticker): void;
}

export const useControlsViewer = (params: IUseControlsViewerParams) => {
    const {
        currentUser,
        sticker,
        updateSticker,
    } = params;
    const {
        addStickerToFavorites,
        removeStickerFromFavorites,
    } = useStickers({ streamerChannelName: currentUser?.channel?.name });

    const onFavoritesClick = async () => {
        let updatedSticker: ISticker;

        if (sticker.isFavorite) {
            updatedSticker = await removeStickerFromFavorites(sticker);
        } else {
            updatedSticker = await addStickerToFavorites(sticker);
        }

        updateSticker(updatedSticker);
    };

    return {
        onFavoritesClick,
    };
};
