import {
    Button,
    Row,
    Slider,
    TextField,
    EComponentColor,
} from '@hyperclap/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconSoundActiveLine } from '@assets/images/svg';
import { ISticker, IUser } from '@typings';

import s from './ControlPopupSettings.scss';
import { useControlPopupSettings } from './hooks';
import { ControlPopup } from '../ControlPopup';


interface IControlPopupSettingsProps {
    sticker: ISticker;
    currentUser?: IUser;
    updateSticker(stickerData: ISticker): void;
    onClose(): void;
}

export const ControlPopupSettings = (props: IControlPopupSettingsProps) => {
    const {
        sticker,
        currentUser,
        updateSticker,
        onClose,
    } = props;
    const {
        price,
        volume,
        onPriceChange,
        onVolumeChange,
        onSubmit,
    } = useControlPopupSettings({
        sticker,
        currentUser,
        updateSticker,
    });
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`sticker-popup.bottom.controls.popups.settings.${id}`);
    const tc = (id: string) => translation(`common.${id}`);

    const handleSubmitClick = async () => {
        await onSubmit();
        onClose();
    };

    return (
        <ControlPopup
            className={s.root}
            onClose={onClose}
        >
            <Row className={s.row}>
                <TextField
                    className={s.input}
                    caption={t('price')}
                    defaultValue={String(price)}
                    invalidSymbols={/[^0-9]/}
                    debounceTime={500}
                    onChanged={onPriceChange}
                />
            </Row>

            <Row className={s.row}>
                <Slider
                    caption={t('volume')}
                    className={s.volumeSlider}
                    showPosition
                    minValue={0}
                    maxValue={100}
                    initialValue={volume}
                    realtimeUpdate
                    onCommit={onVolumeChange}
                />
                <Button
                    circle
                    className={s.volumeButton}
                    icon={<IconSoundActiveLine/>}
                />
            </Row>

            <Row className={s.row}>
                <Button
                    caption={tc('save')}
                    color={EComponentColor.ACCENT}
                    onClick={handleSubmitClick}
                />
            </Row>
        </ControlPopup>
    );
};
