// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Bottom__root--OaUe9 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  height: 220px;
  padding: 18px 0 30px;
}

.Bottom__bottomLine--oMdoA {
  padding: 0 25px;
}
.Bottom__bottomLineLeft--KSpEw {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/base/views/modals/StickerPopup/components/Bottom/Bottom.scss","webpack://./src/base/views/modals/StickerPopup/StickerPopup.const.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,sBAAA;EACA,aCNY;EDOZ,oBAAA;AADJ;;AAIA;EACI,eAAA;AADJ;AAGI;EACI,aAAA;AADR","sourcesContent":["@use '../../StickerPopup.const';\n\n.root {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    box-sizing: border-box;\n    height: StickerPopup.$height-bottom;\n    padding: 18px 0 30px;\n}\n\n.bottomLine {\n    padding: 0 25px;\n\n    &Left {\n        display: flex;\n    }\n}\n","$height-top: 100px;\n$height-bottom: 220px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Bottom__root--OaUe9`,
	"bottomLine": `Bottom__bottomLine--oMdoA`,
	"bottomLineLeft": `Bottom__bottomLineLeft--KSpEw`
};
export default ___CSS_LOADER_EXPORT___;
