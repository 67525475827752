import {
    Column,
    Loader,
    useModals,
    IModalContentWithOptions } from '@hyperclap/ui';
import React, { useEffect } from 'react';

import { TApp, useAppActions, useElementOnScreen } from '@hooks';
import { useAppSelector } from '@hooks/store/AppSelector';
import { ISticker, IStickerCustomSettings } from '@typings';
import { Empty, StickersGrid, useStickerPopup, EStickerPopupControlsMode } from '@views';

import s from './Desktop.scss';


interface IDesktopProps extends TApp {}

export const Desktop = (props: IDesktopProps) => {
    const {
        personalArea: {
            catalogue: {
                isPageLoadingAllowed,
                isStickersFetching,
                stickers,
                updateLoadedSticker,
                loadNextPage,
            },
        },
        stickers: {
            switchStickerFavoriteState,
            switchStickerInChannelState,
            changeStickerCustomSettings,
            deleteSticker,
        },
        translation: {
            t,
        },
    } = props;

    const playSoundOnStickerHover = useAppSelector((state) => state.app.playSoundOnStickerHover);
    const { switchPlaySoundOnStickerHover } = useAppActions();

    const {
        elementRef: watchdogRef,
        isOnScreen: needToLoadPage,
    } = useElementOnScreen<HTMLDivElement>({ threshold: 0 });

    const updateStickerFavoriteState = async (sticker: ISticker) => {
        return await switchStickerFavoriteState(sticker, updateLoadedSticker);
    };

    const updateStickerInChannelState = async (sticker: ISticker) => {
        return await switchStickerInChannelState(sticker, updateLoadedSticker);
    };

    const updateStickerCustomSettings = async (sticker: ISticker, newSettings: IStickerCustomSettings) => {
        return await changeStickerCustomSettings(sticker, newSettings, updateLoadedSticker);
    };

    const {
        showStickerPopup,
        setStickerPopupItem,
    } = useStickerPopup({
        ...props,
        updateCustomSettings: updateStickerCustomSettings,
        updateFavoriteState: updateStickerFavoriteState,
        updateInChannelState: updateStickerInChannelState,
        deleteSticker: deleteSticker,
        controlsMode: EStickerPopupControlsMode.STREAMER,
    });

    const onStickerClick = (sticker: ISticker) => {
        setStickerPopupItem(sticker);
        showStickerPopup();
    };

    useEffect(() => {
        if (needToLoadPage) {
            void loadNextPage();
        }
    }, [needToLoadPage]);

    return (
        <Column margin={{ top: 23 }}>
            { stickers.length > 0
                ? <StickersGrid
                    stickers={stickers}
                    stickersSoundEnabled={playSoundOnStickerHover}
                    onStickerClick={onStickerClick}
                    onSwitchSound={switchPlaySoundOnStickerHover}
                />
                : isStickersFetching
                    ? <Loader margin={{ top: 70 }} />
                    : <Empty
                        padding={{ top: 70 }}
                        iconSize={50}
                        text={t('personal-area.catalogue.noStickers')}
                    />
            }
            {isPageLoadingAllowed &&
                <div className={s.nextPageWatchdog}>
                    <div className={s.nextPageWatchdogMarker} ref={watchdogRef}/>
                </div>
            }
        </Column>
    );
};
