import { Column, Row } from '@hyperclap/ui';
import React from 'react';

import { useApp } from '@hooks';
import { InformationPage } from '@hyperclap/views';

import s from './Support.scss';

export const Support = () => {
    const app = useApp();

    return (
        <InformationPage {...app}>
            <Column className={s.contentArea}>
                <Row className={s.title}>{'SUPPORT'}</Row>
                <Row>
                    <Column>
                        <p>
                            If you encounter any issues with the service, you can contact<br/>
                            our technical support at: support@hyperclap.com
                            <a className={s.link} href="mailto:support@hyperclap.com">support@hyperclap.com</a>
                        </p>

                        <p>
                            Note!<br/>
                            Please always include your ID (you can find it in the Settings in the side menu)..
                        </p>
                    </Column>
                </Row>
            </Column>
        </InformationPage>
    );
};
