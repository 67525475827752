import { Button, Column, EColumnAlignment, EComponentColor, EComponentSize, Hint, Label, Row } from '@hyperclap/ui';
import React from 'react';

import { IconImageSolid } from '@assets/images/svg';
import { TApp } from '@hooks';
import { Video } from '@views/fragments/Sticker/components';

interface IFinishProps extends TApp {}

export const Finish = (props: IFinishProps) => {
    const {
        stickersCreation: {
            sticker,
            preparedData,
            playPreview,
            resetState,
        },
        translation: {
            t,
        },
    } = props;

    return (
        <Column grow rowGap={10} alignment={EColumnAlignment.CENTER}>
            <Column
                rowGap={10}
                margin={{ top: 40 }}
                alignment={EColumnAlignment.CENTER}
                width={470}
            >
                <Video
                    source={sticker ? sticker.stickerUrl : preparedData?.preparedUrl}
                    maxWidth={500}
                    maxHeight={350}
                />

                <Label caption={t('sticker-creation.finish.sticker-is-ready')} size={EComponentSize.LARGE} />

                <Hint muted padding={{ bottom: 15 }} style={{ textAlign: 'center' }}>
                    {t('sticker-creation.finish.sticker-is-moderated')}
                </Hint>

                <Row>
                    <Button
                        caption={t('sticker-creation.finish.create-another')}
                        color={EComponentColor.ACCENT}
                        width={255}
                        onClick={resetState}
                    />
                </Row>

                <Row>
                    <Button
                        caption={t('common.preview')}
                        icon={<IconImageSolid/>}
                        width={255}
                        onClick={playPreview}
                    />
                </Row>
            </Column>
        </Column>
    );
};
