import {
    Column,
    Loader,
    useModals,
    IModalContentWithOptions } from '@hyperclap/ui';
import React, { useEffect } from 'react';

import { IconCheckCircleSolid, IconPoopSolid } from '@assets/images/svg';
import { TApp, useAppActions, useElementOnScreen } from '@hooks';
import { ChannelMode } from '@hooks/app/personal-area/Channel';
import { useAppSelector } from '@hooks/store/AppSelector';
import { ISticker } from '@typings';
import {
    Empty,
    EStickerPopupControlsMode,
    ITabParams,
    StickerPopupOld,
    StickersGrid,
    Tabs,
    useStickerPopup
} from '@views';

import s from './Desktop.scss';


interface IDesktopProps extends TApp {}

export const Desktop = (props: IDesktopProps) => {
    const {
        personalArea: {
            channel: {
                channelMode,
                isPageLoadingAllowed,
                isStickersFetching,
                stickers,
                deleteLoadedSticker,
                loadNextPage,
                changeChannelMode,
                updateLoadedSticker,
            },
        },
        stickers: {
            switchStickerFavoriteState,
            switchStickerInChannelState,
            changeStickerCustomSettings,
            deleteSticker,
        },
        translation: {
            t,
        },
    } = props;

    const TABS: Array<ITabParams<ChannelMode>> = [
        { caption: t('personal-area.channel.channel'), tag: ChannelMode.CHANNEL },
        { caption: t('personal-area.channel.blacklist'), tag: ChannelMode.BLACKLIST },
    ];

    const playSoundOnStickerHover = useAppSelector((state) => state.app.playSoundOnStickerHover);
    const { switchPlaySoundOnStickerHover } = useAppActions();

    const {
        elementRef: watchdogRef,
        isOnScreen: needToLoadPage,
    } = useElementOnScreen<HTMLDivElement>({ threshold: 0 });

    const updateStickerFavoriteState = async (sticker: ISticker) => {
        return await switchStickerFavoriteState(sticker, updateLoadedSticker);
    };

    const updateStickerInChannelState = async (sticker: ISticker) => {
        return await switchStickerInChannelState(sticker, (sticker) => {
            updateLoadedSticker(sticker);
            if (!sticker.isAddedToChannel) deleteLoadedSticker(sticker);
        });
    };

    const {
        showStickerPopup,
        setStickerPopupItem,
    } = useStickerPopup({
        ...props,
        updateCustomSettings: changeStickerCustomSettings,
        updateFavoriteState: updateStickerFavoriteState,
        updateInChannelState: updateStickerInChannelState,
        deleteSticker: deleteSticker,
        controlsMode: EStickerPopupControlsMode.STREAMER,
    });

    const onTabSelected = (tab: ChannelMode = ChannelMode.CHANNEL) => {
        changeChannelMode(tab);
    };

    const onStickerClick = (sticker: ISticker) => {
        setStickerPopupItem(sticker);
        showStickerPopup();
    };

    useEffect(() => {
        if (needToLoadPage) {
            void loadNextPage();
        }
    }, [needToLoadPage]);

    return (
        <Column margin={{ top: 23 }}>
            <Tabs activeTab={channelMode} onSelectionChange={onTabSelected} tabs={TABS}/>
            { stickers.length > 0
                ? <StickersGrid
                    stickers={stickers}
                    stickersSoundEnabled={playSoundOnStickerHover}
                    onStickerClick={onStickerClick}
                    onSwitchSound={switchPlaySoundOnStickerHover}
                />
                : isStickersFetching
                    ? <Loader margin={{ top: 70 }}/>
                    : <Empty
                        padding={{ top: 70 }}
                        icon={channelMode === ChannelMode.CHANNEL ? <IconCheckCircleSolid/> : <IconPoopSolid/>}
                        iconSize={50}
                        text={t(channelMode === ChannelMode.CHANNEL
                            ? 'personal-area.channel.noStickers'
                            : 'personal-area.channel.noBlacklistedStickers')
                        }
                    />
            }
            {isPageLoadingAllowed &&
                <div className={s.nextPageWatchdog}>
                    <div className={s.nextPageWatchdogMarker} ref={watchdogRef}/>
                </div>
            }
        </Column>
    );
};
