import { useStickers } from '@hooks/app/stickers';
import { ISticker, IUser } from '@typings';

interface IUseControlPopupDeleteParams {
    sticker: ISticker;
    currentUser?: IUser;
}

export const useControlPopupDelete = (params: IUseControlPopupDeleteParams) => {
    const {
        sticker,
        currentUser,
    } = params;
    const {
        deleteSticker,
    } = useStickers({ streamerChannelName: currentUser?.channel?.name });

    const onDelete = async () => {
        await deleteSticker(sticker);
    };

    return {
        onDelete,
    };
};
