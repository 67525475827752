import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    IconHeartLine,
    IconHeartSolid,
} from '@assets/images/svg';
import { ISticker, IUser } from '@typings';

import s from './ControlsViewer.scss';
import { useControlsViewer } from './hooks';

interface IControlsViewerProps {
    sticker: ISticker;
    currentUser?: IUser;
    updateSticker(stickerData: ISticker): void;
}

export const ControlsViewer = (props: IControlsViewerProps) => {
    const {
        sticker,
        currentUser,
        updateSticker,
    } = props;
    const {
        onFavoritesClick,
    } = useControlsViewer({
        sticker,
        currentUser,
        updateSticker,
    });
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`sticker-popup.bottom.viewerControls.${id}`);

    return (
        <div className={s.root}>
            <div
                className={s.control}
                onClick={onFavoritesClick}
            >
                {sticker.isFavorite
                    ? (
                        <IconHeartSolid
                            className={
                                cn(
                                    s.controlIcon,
                                    s.controlIconHeart,
                                    s.controlIconHeartActive,
                                )
                            }
                        />
                    )
                    : (
                        <IconHeartLine
                            className={
                                cn(
                                    s.controlIcon,
                                    s.controlIconHeart,
                                )
                            }
                        />
                    )
                }

                <span className={s.controlText}>
                    {sticker?.likedCount ? sticker.likedCount.toString() : t('addToFavorites')}
                </span>
            </div>
        </div>
    );
};
