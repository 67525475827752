import cn from 'classnames';
import React from 'react';

import {
    IconCopyrightLine, IconEtmaASignSolid,
    IconEtmaESignSolid,
    IconEtmaMSignSolid,
    IconEtmaTSignSolid,
    IconTwitchUnsafeLine,
} from '@assets/images/svg';
import { EtmaRating, ISticker } from '@typings';

import s from './Flags.scss';

interface IFlagsProps {
    sticker: ISticker;
}

export const Flags = (props: IFlagsProps) => {
    const { sticker } = props;

    return (
        <div className={s.root}>
            { sticker.hasCopyright && <div className={cn(s.flag, s.flagCopyright)}><IconCopyrightLine/></div> }
            { sticker.isNotTwitchSafe && <div className={cn(s.flag)}><IconTwitchUnsafeLine/></div> }
            { sticker.etmaRating === EtmaRating.E && <div className={cn(s.flag)}><IconEtmaESignSolid/></div> }
            { sticker.etmaRating === EtmaRating.T && <div className={cn(s.flag)}><IconEtmaTSignSolid/></div> }
            { sticker.etmaRating === EtmaRating.M && <div className={cn(s.flag)}><IconEtmaMSignSolid/></div> }
            { sticker.etmaRating === EtmaRating.A && <div className={cn(s.flag)}><IconEtmaASignSolid/></div> }
        </div>
    );
};
