import { getHumanReadableNumber } from '@hyperclap/utils';
import React from 'react';

import { IconSendMessageSolid } from '@assets/images/svg';
import { ISticker } from '@typings';

import s from './SentCounter.scss';

interface ISentCounterProps {
    sticker: ISticker;
}

export const SentCounter = (props: ISentCounterProps) => {
    const { sticker } = props;

    return (
        <div className={s.root}>
            <IconSendMessageSolid className={s.icon}/>
            {sticker.usageCount
                ? getHumanReadableNumber(sticker.usageCount)
                : 0
            }
        </div>
    );
};
