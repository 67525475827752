// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TagsList__root--Jhre9 {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/base/views/modals/StickerPopup/components/Top/components/TagsList/TagsList.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ","sourcesContent":[".root {\n    display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `TagsList__root--Jhre9`
};
export default ___CSS_LOADER_EXPORT___;
